export const SERVICE_CONFIGURATION = 'service_configuration';
export const UI_CONFIGURATION = 'ui_configuration';
export const ADD_LOCALE = 'add_locale';
export const UPDATE_SERVICE_DATA = 'update_service_data';
export const SET_META = 'set_meta';
export const SET_STATUS = 'set_status'
export const SET_REDIRECT_URL = 'set_redirect_url'
export const SET_MUSTACHE_CLASS = 'set_mustache_class';
export const TOGGLE_FEATURED = 'toggle_featured';
export const SET_OVERVIEW = 'set_overview';
export const TOGGLE_TAB = 'toggle_tab';
export const UPDATE_TITLE_TRANSLATION = 'update_title_translation';
export const UPDATE_DESCRIPTION_TRANSLATION = 'update_description_translation';
export const ADD_NEW_FIELD_GROUP = 'add_new_field_group';
export const DELETE_FIELD_GROUP = 'delete_field_group';
export const ADD_NEW_FIELD = 'add_new_field';
export const DELETE_FIELD = 'delete_field';
export const UPDATE_FIELD_TYPE = 'update_field_type';
export const UPDATE_FIELD_NAME = 'update_field_name';
export const TOGGLE_FIELD_REQUIRED = 'toggle_field_required';
export const TOGGLE_FIELD_READONLY = 'toggle_field_readonly';
export const ADD_FIELD_OPTION = 'add_field_option';
export const UPDATE_FIELD_OPTION = 'update_field_option';
export const UPLOAD_ICON = 'upload_icon';
export const UPDATE_BUTTON_ONCLICK = 'update_button_onclick';
export const UPDATE_TAB_TITLE_TRANSLATION = 'update_tab_title_translation';
export const UPDATE_TAB_DESCRIPTION_TRANSLATION = 'update_tab_description_translation';
export const ADD_NEW_TAB = 'add_new_tab';
export const UPDATE_FIELD_GROUP_TITLE_TRANSLATION = 'update_field_group_title_translation';
export const TOGGLE_SHOW_TAB_CARD_HEADER = 'toggle_show_tab_card_header';
export const UPDATE_FIELD_TITLE_TRANSLATION = 'update_field_title_translation';
export const UPDATE_FIELD_DESCRIPTION_TRANSLATION = 'update_field_description_translation';
export const UPDATE_FIELD_PLACEHOLDER_TRANSLATION = 'update_field_placeholder_translation';
export const TOGGLE_MULTIPLE_INSTANCES_META = 'toggle_multiple_instances_meta';
export const UPDATE_IFRAME_SRC = 'update_iframe_src';
export const UPDATE_SERVICE_DATA_FIELD = 'update_service_data_field';
export const UPDATE_SOURCE_HEADER = 'update_source_header';
export const UPDATE_TARGET_HEADER = 'update_target_header';
export const UPDATE_DEFAULT_MAPPINGS = 'update_default_mappings';