import { styled, TextField } from '@mui/material';
import { Card, Checkbox, Grid, Select, SelectItem, Tooltip, Typography } from '@platform-ui/design-system'
import React, { Dispatch, useRef, useState } from 'react'
import { useStoreContext, Action } from '../../../../Store';
import { SET_META, SET_REDIRECT_URL, SET_STATUS, TOGGLE_MULTIPLE_INSTANCES_META, UPDATE_SERVICE_DATA, UPLOAD_ICON, UPDATE_SERVICE_DATA_FIELD } from '../../actionTypes';
import { PLACEHOLDER_SERVICE_REQUEST } from '../../constants';
import { IntegrationAppFormState } from '../../state';
import Connect from '../../../../Connect/Connect';
import { useFlipperHook } from '../../../../Tables/helpers';

function ServiceConfigurationTab(props: any) {
  const { state, dispatch } = useStoreContext() as { state: IntegrationAppFormState, dispatch: Dispatch<Action> };
  const serviceData = state.service_data || {};
  const [skipCreate, setSkipCreate] = useState(serviceData?.create?.skip === undefined ? true : serviceData?.create?.skip);
  const [skipUpdate, setSkipUpdate] = useState(serviceData?.update?.skip === undefined ? true : serviceData?.update?.skip);
  const [skipDelete, setSkipDelete] = useState(serviceData?.delete?.skip === undefined ? true : serviceData?.delete?.skip);
  const [createData, setCreateData] = useState(serviceData.create === undefined ?  PLACEHOLDER_SERVICE_REQUEST : JSON.stringify(serviceData.create, null, 2));
  const [updateData, setUpdateData] = useState(serviceData.update === undefined ?  PLACEHOLDER_SERVICE_REQUEST : JSON.stringify(serviceData.update, null, 2));
  const [deleteData, setDeleteData] = useState(serviceData.delete === undefined ?  PLACEHOLDER_SERVICE_REQUEST : JSON.stringify(serviceData.delete, null, 2) );
  const [inputErrors, setInputErrors] = useState({
    create: false,
    update: false,
    delete: false,
  });
  const multipleInstancesTooltipTitle = `Only applicable for connectors with multiple instances looked up by external_id. Please ignore it if you don't know what that means.`;
  const serviceDataCustomFields = Object.keys(serviceData).filter(key => !(['create', 'update', 'delete', 'icon', 'simple_url_or_path', 'workflow_template'].includes(key)));
  const [showCreateUpdateDelete, _] = useFlipperHook('ih_show_create_update_delete_in_admin_console');

  const isValidJSON = (text: string) => {
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleServiceDataChange = (field: string, value: any) => {
    const isSkip = value === 'skip' || value === '';
    if (!isSkip) {
      setInputErrors((prevInputErrors) => ({
        ...prevInputErrors,
        [field]: !isValidJSON(value),
      }));
    }

    const parsedValue = isValidJSON(value) ? JSON.parse(value) : value;
    const payload = (() => {
      switch (field) {
        case 'create':
          if (!isSkip) {
            setCreateData(value);
          }
          return {
            create: isSkip ? { "skip": true } : parsedValue,
            update: skipUpdate ? { "skip": true } : updateData,
            delete: skipDelete ? { "skip": true } : deleteData
          };
        case 'update':
          if (!isSkip) {
            setUpdateData(value);
          }
          return {
            create: skipCreate ? { "skip": true } : createData,
            update: isSkip ? { "skip": true } : parsedValue,
            delete: skipDelete ? { "skip": true } : deleteData
          };
        case 'delete':
          if (!isSkip) {
            setDeleteData(value);
          }
          return {
            create: skipCreate ? { "skip": true } : createData,
            update: skipUpdate ? { "skip": true } : updateData,
            delete: isSkip ? { "skip": true } : parsedValue
          };
        default:
          return {};
      }
    })();
    
    if (isValidJSON(value) || isSkip) {
      dispatch({ type: UPDATE_SERVICE_DATA, payload });
    }
  };

  const setProduct = (event) => {
    dispatch({
      type: SET_META,
      payload: {
        meta: {"product": event.target.value}
      }
    });
  };

  const setCategory = (event) => {
    dispatch({
      type: SET_META,
      payload: {
        meta: {"category": event.target.value}
      }
    });
  };

  const setStatus = (event) => {
    dispatch({
      type: SET_STATUS,
      payload: {
        status: event.target.value
      }
    });
  };

  const setRedirectUrl = (event) => {
    dispatch({
      type: SET_REDIRECT_URL,
      payload: {
        redirect_url: event.target.value
      }
    });
  };

  const toggleFeatured = (event) => {
    dispatch({
      type: SET_META,
      payload: {
        meta: {"featured": !state.meta['featured']}
      }
    });
  };

  const toggleMultipleInstances = (event) => {
    dispatch({
      type: TOGGLE_MULTIPLE_INSTANCES_META,
      payload: event.target.checked
    });
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const uploadImage = (event) => {
    dispatch({
      type: UPLOAD_ICON,
      payload: {
        icon: event.target.files[0]
      }
    });
  }

  const ImageWrapper = styled('div')({
    position: 'relative',
    display: 'inline-block',
  });

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleServiceDataFieldChange = (key) => (event) => {
    const value = event.target.value;
    const isJSON = isValidJSON(value);
    dispatch({
      type: UPDATE_SERVICE_DATA_FIELD,
      payload: { key, value, isJSON }
    });
  };

  const toLabel = (text: string) => {
    const result = text.replace(/[_-]/g, ' ');
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  return (
    <Grid container direction="column">
      <Grid item>
      <Card header={'General Data'} id={'general-data'} titleBar body={
        <Grid container>
          <Grid item>
            <ImageWrapper>
              <img
                height="72px"
                width="72px"
                src={state.icon instanceof File ? URL.createObjectURL(state.icon) : state.icon || Connect.public_path() + '/logo_zuora.png'}
                alt="Click to upload"
                onClick={() => fileInputRef.current?.click()}
                style={{ cursor: 'pointer' }}
              />
              <VisuallyHiddenInput type="file" ref={fileInputRef} onChange={uploadImage} />
            </ImageWrapper>
          </Grid>
          <Grid item>
            <Select
              a11yLabel="product"
              dsOnChange={setProduct}
              label="Product"
              placeholder="Select Product"
              required
              value={state.meta['product'] || ''}>
              <SelectItem value='Zuora Billing'>Zuora Billing</SelectItem>
              <SelectItem value='Zuora Revenue'>Zuora Revenue</SelectItem>
              <SelectItem value='Zuora Payments'>Zuora Payments</SelectItem>
              <SelectItem value='Zephyr'>Zephyr</SelectItem>
            </Select>
          </Grid>
          <Grid item>
            <Select a11yLabel="category"
              dsOnChange={setCategory}
              label="Category"
              placeholder="Select Category"
              required
              value={state.meta['category'] || ''}>
              <SelectItem value='CRM'>CRM</SelectItem>
              <SelectItem value='AppStore'>AppStore</SelectItem>
              <SelectItem value='CPQ'>CPQ</SelectItem>
              <SelectItem value='ERP'>ERP</SelectItem>
              <SelectItem value='Data Warehouse'>Data Warehouse</SelectItem>
              <SelectItem value='Payment Gateway'>Payment Gateway</SelectItem>
              <SelectItem value='Query Service'>Query Service</SelectItem>
              <SelectItem value='Cloud Object Storage'>Cloud Object Storage</SelectItem>
              <SelectItem value='Database'>Database</SelectItem>
              <SelectItem value='Coming Soon'>Coming Soon</SelectItem>
            </Select>
          </Grid>
          <Grid item>
            <Select a11yLabel="status"
              dsOnChange={setStatus}
              label="Status"
              placeholder="Select Status"
              required
              value={state.status || '' }>
              <SelectItem value='Public'>Public</SelectItem>
              <SelectItem value='Private'>Private</SelectItem>
            </Select>
          </Grid>
          <Grid item>
            <TextField label='Redirect Url' placeholder="Redirect Url" value={state.redirect_url} onChange={setRedirectUrl} />
          </Grid>
          <Grid item sx={{marginTop: '28px'}}>
            <Checkbox
              dsOnChange={toggleMultipleInstances}
              label={
                <Tooltip
                  dsOnClose={() => {}}
                  dsOnOpen={() => {}}
                  e2e='multiple-instances-tooltip'
                  title={<Typography>{multipleInstancesTooltipTitle}</Typography>}
                >
                  {<>Multiple Instances</>}
                </Tooltip>
              }
              checked={!!state.meta['multiple']}
            />
          </Grid>
          <Grid item sx={{marginTop: '28px'}}>
            <Checkbox dsOnChange={toggleFeatured} label='Featured' checked={state.meta['featured']} />
          </Grid>
        </Grid>
      } />
      </Grid>
      <Grid item>
      <Card header='Service Data' id='service-data' titleBar body={
        <Grid container direction='column'>
          <Grid item>
            {
              ('name' in state.service_data) &&
                <TextField
                onChange={handleServiceDataFieldChange('icon')}
                label='Icon URL'
                value={state.service_data['icon'] || ''}
                type='text'
                placeholder='https://nav-assets.zuora.life/img/zuora-wordmark.svg'
              />
            }
          </Grid>
          <Grid item>
            <TextField
              onChange={handleServiceDataFieldChange('simple_url_or_path')}
              label='Keep it simple stupid URL or flow path'
              value={state.service_data['simple_url_or_path'] || ''}
              type='text'
              placeholder='f/staging_example/python_script_example'
            />
          </Grid>
          {
            showCreateUpdateDelete &&
            <Grid item>
              <Grid container direction='row'>
                <Grid item xs={4}>
                  <h4>Create</h4>
                  <Checkbox
                    dsOnChange={() => {
                      setSkipCreate(!skipCreate);
                      handleServiceDataChange('create', 'skip');
                    }}
                    label='Skip'
                    checked={skipCreate}
                  />
                  {!skipCreate && (
                    <TextField
                      multiline
                      placeholder="Enter Create Data"
                      value={createData}
                      error={inputErrors.create}
                      helperText={inputErrors.create ? "Invalid JSON" : ""}
                      onChange={(event) => {
                        const value = event.target.value;
                        handleServiceDataChange('create', value);
                      }}
                      style={{ width: '100%', height: '100%' }}
                    />
                  )}
                </Grid>

                <Grid item xs={4}>
                  <h4>Update</h4>
                  <Checkbox
                    dsOnChange={() => {
                      setSkipUpdate(!skipUpdate);
                      handleServiceDataChange('update', 'skip');
                    }}
                    label='Skip'
                    checked={skipUpdate}
                  />
                  {!skipUpdate && (
                    <TextField
                      multiline
                      placeholder="Enter Update Data"
                      value={updateData}
                      error={inputErrors.update}
                      helperText={inputErrors.update ? "Invalid JSON" : ""}
                      onChange={(event) => {
                        const value = event.target.value;
                        handleServiceDataChange('update', value);
                      }}
                      style={{ width: '100%', height: '100%' }}
                    />
                  )}
                </Grid>

                <Grid item xs={4}>
                  <h4>Delete</h4>
                  <Checkbox
                    dsOnChange={() => {
                      setSkipDelete(!skipDelete);
                      handleServiceDataChange('delete', 'skip');
                    }}
                    label='Skip'
                    checked={skipDelete}
                  />
                  {!skipDelete && (
                    <TextField
                      multiline
                      placeholder="Enter Delete Data"
                      value={deleteData}
                      error={inputErrors.delete}
                      helperText={inputErrors.delete ? "Invalid JSON" : ""}
                      onChange={(event) => {
                        const value = event.target.value;
                        handleServiceDataChange('delete', value);
                      }}
                      style={{ width: '100%', height: '100%' }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          }
          <Grid item>
            <TextField
              onChange={handleServiceDataFieldChange('workflow_template')}
              label='Workflow Template JSON'
              value={state.service_data['workflow_template'] ? JSON.stringify(state.service_data['workflow_template'], null, 4) : ''}
              type='text'
              placeholder='Workflow Template JSON'
              InputProps={{
                sx: {
                  '& textarea': {
                    resize: 'both'
                  },
                  '&&': {
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    height: 'auto',
                  }
                }
              }}
              minRows={5}
              maxRows={10}
              multiline={true}
            />
          </Grid>
        </Grid>
      } />
      </Grid>
    </Grid>
  )
}

export default ServiceConfigurationTab
