import React, { Dispatch, useEffect, useState } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Button, Card, Checkbox, Grid, TextField, Toggle, Typography } from '@platform-ui/design-system';
import { useStoreContext, Action } from '../../../../Store';
import { IntegrationAppFormState } from '../../state';
import { Divider, List, ListItem } from '@mui/material';
import { ADD_NEW_FIELD, ADD_NEW_FIELD_GROUP, ADD_NEW_TAB, DELETE_FIELD_GROUP, TOGGLE_SHOW_TAB_CARD_HEADER, TOGGLE_TAB, UPDATE_IFRAME_SRC } from '../../actionTypes';
import FieldBlock from './components/FieldBlock';

interface CheckboxItemProps {
  name: string;
  label: string;
  index: number;
  disabled?: boolean;
  moveCheckbox: (fromIndex: number, toIndex: number) => void;
}

const CheckboxItem: React.FC<CheckboxItemProps> = ({name, label, index, disabled, moveCheckbox }) => {
  const { state, dispatch } = useStoreContext() as { state: IntegrationAppFormState, dispatch: Dispatch<Action> };

  const [, ref] = useDrag({
    type: 'CHECKBOX',
    item: { index },
  });

  const [, drop] = useDrop({
    accept: 'CHECKBOX',
    hover: (item: { index: number }) => {
      if (item.index !== index) {
        moveCheckbox(item.index, index);
        item.index = index;
      }
    },
  });

  const toggleTab = (event) => {
    dispatch({
      type: TOGGLE_TAB,
      payload: {
        tab: name,
        enabled: !state.ui_layout['tabs'][index][name]['enabled'],
        index: index
      },
    });
  };

  const addNewFieldGroup = (event) => {
    dispatch({
      type: ADD_NEW_FIELD_GROUP,
      payload: {
        tab: name,
        index: index
      }
    });
  }

  function deleteFieldGroup(fieldGroupIndex: number) {
    dispatch({
      type: DELETE_FIELD_GROUP,
      payload: {
        tab: name,
        index: index,
        fieldGroupIndex: fieldGroupIndex
      }
    });
  }

  function addNewField(fieldGroupIndex: number) {
    dispatch({
      type: ADD_NEW_FIELD,
      payload: {
        tab: name,
        index: index,
        fieldGroupIndex: fieldGroupIndex
      }
    });
  }

  const toggleShowTabCardHeader = (event) => {
    dispatch({
      type: TOGGLE_SHOW_TAB_CARD_HEADER,
      payload: {
        tabName: name,
        index: index,
        showHeader: event.target.checked
      }
    });
  };

  const updateIframeSrc = (event) => {
    dispatch({
      type: UPDATE_IFRAME_SRC,
      payload: {
        tabName: name,
        tabIndex: index,
        value: event.target.value
      }
    });
  };

  return (
    <div ref={(node) => ref(drop(node))}>
      <Grid container>
        <Grid item>
          <Checkbox dsOnChange={toggleTab} label={label} checked={state.ui_layout['tabs'][index][name]['enabled']} disabled={disabled}/>
        </Grid>
        <Grid item sx={{ marginTop: '3px' }}>
          <Toggle label='Show Tab Card Header' dsOnChange={toggleShowTabCardHeader} checked={state.ui_layout['tabs'][index][name]['showHeader'] == null ? true : state.ui_layout['tabs'][index][name]['showHeader']} />
        </Grid>
      {
        name.match(/^iframe.*/i)?.input &&
        <Grid item>
          <TextField
            label='Iframe Src Url'
            value={state.ui_layout['tabs'][index][name]['iframe'] || ''}
            dsOnChange={updateIframeSrc}
          />
        </Grid>
      }
      </Grid>
      <Grid container>
        {state.ui_layout['tabs'][index][name]['fieldGroups'] && state.ui_layout['tabs'][index][name]['fieldGroups'].length > 0 && <Grid item xs={12}>
          <List>
            {state.ui_layout['tabs'][index][name]['fieldGroups'].map((fieldGroup, fieldGroupIndex) => {
              return (
                <>
                  <Divider>{<Typography variant='body2'>{`Field Group ${fieldGroupIndex + 1}`}</Typography>}</Divider>
                  <Grid container direction='column'>
                    <Grid item>
                    {fieldGroup['fields'].map((field, fieldIndex) => {
                      return (
                        <ListItem key={`${field}-${fieldIndex}`}>
                          <FieldBlock
                            fieldName={field.name}
                            fieldType={field.fieldType}
                            required={field.required}
                            readonly={field.readonly}
                            tab={name}
                            tabIndex={index}
                            fieldGroupIndex={fieldGroupIndex}
                            fieldIndex={fieldIndex}
                            onClick={field.onClick}
                            rows={field.rows}
                            sourceHeader={field.sourceHeader}
                            targetHeader={field.targetHeader}
                          />
                        </ListItem>
                      )
                    })}
                    </Grid>
                    <Grid sx={{ marginLeft: '15px', marginRight: '15px' }} item>
                      <Button dsOnClick={() => {addNewField(fieldGroupIndex)}}>Add New Field</Button>
                      <Button icon='remove_circle' dsOnClick={() => {deleteFieldGroup(fieldGroupIndex)}}>Delete Field Group</Button>
                    </Grid>
                  </Grid>
                </>
              )
            })}
          </List>
        </Grid>}
        <Grid sx={{ marginLeft: '15px' }} item>
          { !name.match(/^iframe.*/i)?.input && <Button dsOnClick={addNewFieldGroup}>Add New Field Group</Button> }
        </Grid>
      </Grid>
    </div>
  );
};

const UIConfigurationTab: React.FC = () => {
  const { state, dispatch } = useStoreContext() as { state: IntegrationAppFormState, dispatch: Dispatch<Action> };
  const [checkboxes, setCheckboxes] = useState(state.ui_layout['tabs']);

  const moveCheckbox = (fromIndex: number, toIndex: number) => {
    // Need a new action here to handle moving the ui layout
    const updatedCheckboxes = [...checkboxes];
    const [movedCheckbox] = updatedCheckboxes.splice(fromIndex, 1);
    updatedCheckboxes.splice(toIndex, 0, movedCheckbox);
    setCheckboxes(updatedCheckboxes);
  };

  const addNewTab = () => {
    let tabName: string = null;

    do {
      tabName = prompt('Enter a non-empty case-sensitive tab name');
    } while (!tabName);

    dispatch({
      type: ADD_NEW_TAB,
      payload: { tabName }
    });
  };

  function toTitle(text) {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  useEffect(() => {
    if (state.ui_layout['tabs']?.length > 0) {
      setCheckboxes(state.ui_layout['tabs']);
    }
  }, [state.ui_layout['tabs']]);

  // TODO(Xander): Replace the "checkboxes" with separate cards that keep the intended DND functionality
  // rather than a singular card that al the field groups live on
  return (
    <DndProvider backend={HTML5Backend}>
      <Card
        header={
          <>
            <Typography variant='h5'>Tabs</Typography>
            <Typography variant='body1'>authentication, settings, and execution are natively rendered when defined.</Typography>
            <Typography variant='body1'>Other tab names are custom.</Typography>
          </>
        }
        titleBar
        id="tabs-card"
        headerAction={
          <Button endIcon='add_circle_outline' dsOnClick={addNewTab}>New Tab</Button>
        }
        body={
          <>
            <Card
              id='overview-card'
              key='overview-card'
              autoDistance
              variant='outlined'
              body={<Checkbox dsOnChange={() => {}} label={'Overview'} checked={true} disabled={true}/>}
            />
            {checkboxes.map((tab, index) => {
              const tabName = Object.keys(tab)[0]
              // const checkbox = tab[tabName];
              return (
                <Card
                  id={`${tabName}-card-${index}`}
                  key={`${tabName}-card-${index}`}
                  autoDistance
                  variant='outlined'
                  body={
                    <>
                      <CheckboxItem
                        key={`${tab}-${index}`}
                        name={tabName}
                        label={toTitle(tabName)}
                        index={index}
                        // disabled={checkbox.disabled}
                        moveCheckbox={moveCheckbox}
                      />
                    </>
                  }
                />
              )
            })}
          </>
        }
      />
    </DndProvider>
  );
};

export default UIConfigurationTab;
